.adyen-drop-in-container {
    font-size: initial;
    line-height: initial;

    .adyen-checkout__payment-method {
        border-radius: 0 !important;
    }

    .adyen-checkout__payment-method--selected {
        background-color: var(--color-cart-row);
    }
}

.Checkout {
    column-gap: var(--gutter);
    display: grid;
    flex: 1;
    grid-template-columns: repeat(2, 1fr);
    margin-top: var(--header-height);
    padding: 0 var(--gutter);

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Checkout-column {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--gutter);
}

.Checkout-header {
    align-items: center;
    display: flex;
}

.Checkout-headerButton {
    color: var(--color-accent);
    margin-right: calc(var(--gutter) / 2);
    padding: 0;

    &:after {
        content: "→";
        margin-left: calc(var(--gutter) / 2);
        position: relative;
        top: -0.05em;
    }
}

.Checkout-title {}

.Checkout-labels {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    padding: var(--gutter) 0;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Checkout-label {
    background-color: var(--color-cart-row);
    display: block;
    padding: var(--gutter);
    position: relative;
    text-align: left;

    &.is-disabled {
        opacity: 0.75;
    }

    a {
        text-decoration: underline;
    }
}

.Checkout-label--half {}

.Checkout-label--full {
    grid-column: span 2;
}

.Checkout-label--transparent {
    background-color: transparent;
}

.Checkout-returnLink,
.Checkout-backButton,
.Checkout-nextButton {
    display: block;
    padding: var(--gutter);
    text-align: left;
    width: 100%;

    &:hover {
        color: var(--color-accent);
    }
}

.Checkout-returnLink {
    background-color: var(--color-cart-row);
    grid-column: span 2;
}

.Checkout-backButton {
    background-color: var(--color-cart-row);
}

.Checkout-nextButton {
    background-color: var(--color-contrast);
    color: var(--color-base);
    grid-column: span 2;
}

.Checkout-labelValue--light {
    color: var(--color-accent);
}

.Checkout-labelValue--row {
    display: flex;
}

.Checkout-labelText,
.Checkout-labelText--notice,
.Checkout-labelText--full {
    color: var(--color-accent);
    display: block;
    margin-bottom: 0.25rem;

    &:last-child {
        margin-bottom: 0;
        margin-top: 0.25rem;
    }

    .Checkout-label.is-active & {
        color: inherit;
    }

    abbr {
        font-size: 0.9em;
        line-height: 1;
        text-decoration: none;
        vertical-align: top;
    }

    abbr:before,
    abbr:after {
        font-size: 0.75em;
        vertical-align: 0.35em;
    }

    abbr:before {
        content: "(";
    }

    abbr:after {
        content: ")";
    }
}

.Checkout-labelText--full {
    grid-column: span 2;
}

.Checkout-labelText--notice {
    font-style: italic;
    margin-bottom: var(--gutter);
}

.Checkout-fauxSelect {
    display: flex;
    justify-content: space-between;
}

.Checkout-input,
.Checkout-textarea,
.Checkout-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    width: 100%;
    line-height: 1.5em;
}

.Checkout-textarea {
    min-height: 6em;
    resize: vertical;
}

.Checkout-select {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.Checkout-inlineButton {
    bottom: 0;
    padding: var(--gutter);
    position: absolute;
    right: 0;
}

.Checkout-fauxCheckbox {
    display: inline-flex;
    position: relative;
}

.Checkout-checkbox {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.Checkout-fauxCheckboxYes,
.Checkout-fauxCheckboxNo {
    color: var(--color-accent);
}

.Checkout-fauxCheckboxYes {
    input:checked~& {
        color: inherit;
    }
}

.Checkout-fauxCheckboxNo {
    margin-left: 2rem;

    input:not(:checked)~& {
        color: inherit;
    }
}

.Checkout-ingrid {
    margin-top: var(--gutter);

    @media screen and (max-width: 767px) {
        margin-bottom: var(--gutter);
    }
}

.Checkout-adyen {
    padding-top: var(--gutter);
}

.Checkout-footer {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    margin-top: auto;

    @media screen and (max-width: 767px) {
        display: flex;
        flex-flow: column;

        .Checkout-column:first-child & {
            margin-bottom: var(--gutter);
            order: -1;
        }
    }
}

.Checkout-error {
    background: var(--color-cart);
    color: var(--color-accent);
    line-height: var(--line-height-regular);
    padding: 1em;
}