.Grid-projects {
    a:hover & {
        color: var(--color-contrast);
    }

    .Grid-blockContent {
        aspect-ratio: initial;
        display: grid;
        grid-template-columns: repeat(16, calc(100vw / 16));
        overflow: hidden;

        @media screen and (max-width: 767px) {
            -ms-overflow-style: none; // Hide scrollbar
            overflow-x: scroll;
            scrollbar-width: none; // Hide scrollbar
            &::-webkit-scrollbar {
                display: none; // Hide scrollbar
            }
        }
    }

    .Grid-projectsTitle {
        position: relative;
        white-space: nowrap;
        z-index: 1000;

        @media screen and (max-width: 767px) {
            left: 0;
            position: sticky;
            top: 0;
        }
    }

    .Grid-projectsImages {
        display: grid;
        grid-column: span 14;
        grid-template-columns: repeat(16, calc(100vw / 16));
        min-height: 30rem;

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(16, calc(100vw / 8));
            min-height: 20rem;
        }

        .Grid-projectsImg {
            &:last-child {
                figure {
                    pointer-events: all;
                }
            }
        }

        &.is-multiply {
            .Grid-projectsImg {
                mix-blend-mode: lighten;
            }
        }

        &:hover {
            .Grid-projectsImg {
                color: #00000010;
                &:hover {
                    color: #000000;
                }
            }
        }
    }

    .Grid-projectsImg {
        &:hover {
            mix-blend-mode: initial !important;
            z-index: 100 !important;
        }

        &.Grid-projectsImg--horizontal {
            figure {
                width: 500%;
            }
        }
    }

    figure {
        height: 100%;
        margin: calc(var(--line-height-regular) / 2) 0 0;
        pointer-events: none;
        width: 300%;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}
