.Grid {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    margin-bottom: calc(var(--gutter) + var(--line-height-regular));
    padding: calc(var(--gutter) / 2);
    position: relative;
    z-index: 1;

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(8, 1fr);
    }
}
.Grid--flushBottom {
    margin-bottom: 0;
    padding-bottom: 0;
}
.Grid--flushTop {
    padding-top: 0;
}

.Grid-filters {
    aspect-ratio: 2 / 3;
    display: block;
    grid-column: span 4;
    padding: calc(var(--gutter) / 2);
    position: relative;

    @media screen and (max-width: 767px) {
        aspect-ratio: auto;
        background-color: var(--color-base);
        padding: var(--gutter);
        position: absolute;
        width: 100%;
        z-index: 100;
    }
}

.Grid-filtersGroup {
    @media screen and (max-width: 767px) {
        display: none;

        &.is-active {
            display: block;
        }
    }
}

.Grid-filtersGroupSection {
    break-inside: avoid;
    &:not(:last-child) {
        margin-bottom: var(--line-height-regular);
    }
    &:last-child {
        order: 2;
    }
}

.Grid-filters.is-view .Grid-filtersGroup--view {
    display: block;
}

.Grid-filters.is-filters .Grid-filtersGroup--filters {
    display: block;
}

.Grid-filters.is-sort .Grid-filtersGroup--sort {
    display: block;
}

.Grid-filtersGroupSelect {
    display: none;

    @media screen and (max-width: 767px) {
        column-gap: var(--gutter);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        list-style: none;
        padding: 0;
        width: 100%;

        a {
            span {
                display: none;
            }
        }

        .is-selected {
            a {
                span {
                    display: inline;
                    margin-left: 0.25em;
                }
            }
        }
    }
}

.Grid-filtersGroup.u-richText h3 {
    width: 100%;

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.Grid-filtersGroup.u-richText ul {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
        margin-top: var(--gutter);
    }
}

.Grid-filtersGroup--filters {
    @media screen and (max-width: 767px) {
        column-count: 2;
        column-gap: var(--gutter);
        padding-top: var(--gutter);
    }
}

.Grid-filtersGroup--filters.u-richText h3 {
    @media screen and (max-width: 767px) {
        display: block;
        &:not(:first-child) {
            padding-top: var(--line-height-regular);
        }
    }
}

.Grid-filtersGroup--filters.u-richText ul {
    @media screen and (max-width: 767px) {
        flex-flow: column;
        margin-top: 0;
        li:after {
            display: none;
        }
    }
}

.Grid-block {
    display: block;
    grid-column: span 4;
    position: relative;

    @media screen and (max-width: 767px) {
        &:hover {
            .Grid-blockContent--overProductImage {
                opacity: 1;
            }
        }
    }
}
.Grid-block--text {
    @media screen and (max-width: 767px) {
        grid-column: span 8;
    }
}
.Grid-block--wideText {
    display: grid;
    grid-column: span 16;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: auto;
    position: relative;

    .Grid-content {
        padding: calc(var(--gutter) / 2);
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(8, 1fr);
        padding: calc(var(--gutter) / 2) 0;

        .Grid-content {
            padding: 0 calc(var(--gutter) / 2);
        }
    }
}
.Grid-contentColumn1 {
    grid-column-end: 4;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        .Grid-block--wideText:hover &:not(:hover) {
            opacity: 0.1;
        }
    }

    @media screen and (max-width: 767px) {
        grid-column-end: 9;
        grid-column-start: 1;
        margin-bottom: var(--gutter);
    }
}
.Grid-contentColumn2 {
    grid-column-end: 17;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-row-start: 1;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 768px) {
        .Grid-block--wideText:hover &:not(:hover) {
            opacity: 0.1;
        }
    }

    @media screen and (max-width: 767px) {
        grid-column-end: 9;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-row-start: 2;
    }
}
.Grid-contentColumn3 {
    grid-column-end: 17;
    grid-column-start: 5;
    grid-row-end: 2;
    grid-row-start: 1;
    position: relative;
    z-index: 3;

    @media screen and (min-width: 768px) {
        .Grid-block--wideText:hover &:not(:hover) {
            opacity: 0.1;
        }
    }

    @media screen and (max-width: 767px) {
        grid-column-end: 9;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-row-start: 3;
    }
}

.Grid-block--double {
    grid-column: span 8;
}
.Grid-block--large {
    grid-column: span 8;
    grid-row: span 2;

    @media screen and (max-width: 768px) {
        .Grid-blockContent--overProductImage {
            background-color: transparent;
            color: var(--color-base);
            opacity: 1;
            padding: var(--gutter);
        }
        display: flex;
        flex-flow: column;
    }
}
.Grid-block--full {
    grid-column: span 16;
    grid-row: span 2;

    .Grid-blockGraphic {
        aspect-ratio: initial;
    }

    @media screen and (max-width: 767px) {
        grid-column: span 8;
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-block--large .Grid-blockGraphic,
    .Grid-block--large .Grid-blockContent {
        height: var(--grid-block-height--large);
    }
    .Grid-block--full .Grid-blockGraphic,
    .Grid-block--large .Grid-blockContent {
        height: var(--grid-block-height--full);
    }
}

.Grid-blockLink {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.Grid-blockHeading {
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
    padding: calc(var(--gutter) / 2);
}

.Grid-blockContent {
    aspect-ratio: 2 / 3;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-regular);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-regular);
    padding: calc(var(--gutter) / 2);

    &.is-light-text {
        color: var(--color-base);

        @media screen and (max-width: 767px) {
            &.Grid-gridBlock {
                color: var(--color-black);
            }
        }
    }

    @media screen and (max-width: 767px) {
        .Grid-block--text & {
            aspect-ratio: auto;
        }
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockContent {
        height: var(--grid-block-height);
    }

    @media screen and (max-width: 767px) {
        .Grid-block--text .Grid-blockContent {
            height: auto;
        }
    }
}

.Grid-blockContent--product {
    aspect-ratio: auto;
    flex-direction: row;

    a {
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
        }
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockContent--product {
        height: auto;
    }
}
.Grid-blockContent--overProductImage {
    aspect-ratio: auto;
    bottom: 0;
    flex-direction: row;
    left: 0;
    padding: var(--gutter);
    position: absolute;
    right: 0;

    @media screen and (max-width: 767px) {
        background-color: var(--color-base);
        opacity: 0;
        padding: calc(var(--gutter) / 2) calc(var(--gutter) / 2) var(--gutter);
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockContent--overProductImage {
        height: auto;
    }
}

.Grid-filterPicker {
    cursor: pointer;
    position: relative;

    input {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
    }
    &:hover span,
    input:checked + span {
        color: var(--color-accent);
    }
}

.Grid-productName {
    display: inline;

    &:after {
        content: ",";
        margin-right: var(--blank-space-width);

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        display: block;
    }
}
.Grid-productPrice {
    display: inline;

    &.is-soldOut {
        color: var(--color-accent);
    }
}
.Grid-productAdd {
    border: none;
    margin: 0 0 0 auto;
    opacity: 0;
    padding: 0;
    transition: opacity var(--transition-duration)
        var(--transition-timing-function);

    @media (hover) {
        .Grid-block:hover & {
            opacity: 1;
        }

        &:enabled:hover {
            color: var(--color-accent);
        }
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.Grid-blockGraphic {
    aspect-ratio: 2 / 3;
    display: grid;
    grid-column: span 4;
    margin: 0;
    padding: calc(var(--gutter) / 2);

    > img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockGraphic {
        border: calc(var(--gutter) / 2) solid transparent;
        box-sizing: border-box;
        height: var(--grid-block-height);
        overflow: hidden;
        padding: 0;
        position: relative;
        width: 100%;
    }
}

.Grid-blockGraphicImg {
    margin: 0;
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.Grid-blockGraphicImg,
.Grid-blockGraphicHoverImg {
    grid-column: 1;
    grid-row: 1;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.Grid-blockGraphic--landscape {
    aspect-ratio: 8 / 6;
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockGraphic--landscape {
        height: var(--grid-block-height--landscape);

        @media screen and (max-width: 767px) {
            height: auto;
        }
    }
}

.Grid-blockGraphicHoverImg {
    --transition-duration: 0.2s;

    opacity: 0;
    transition: opacity var(--transition-duration) linear,
        visibility var(--transition-duration) step-end;
    visibility: hidden;

    .Grid-blockGraphic:hover & {
        opacity: 1;
        transition: opacity var(--transition-duration) linear,
            visibility var(--transition-duration) step-start;
        visibility: visible;
    }
}

.Grid-blockSpacer {
    aspect-ratio: 2 / 3;

    @media screen and (max-width: 767px) {
        aspect-ratio: initial;
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockSpacer {
        height: var(--grid-block-height);

        @media screen and (max-width: 767px) {
            height: auto;
        }
    }
}

.Grid-blockSpacer--landscape {
    aspect-ratio: 8 / 6;

    @media screen and (max-width: 767px) {
        aspect-ratio: initial;
    }
}

@supports not (aspect-ratio: 2 / 3) {
    .Grid-blockSpacer--landscape {
        height: var(--grid-block-height--landscape);

        @media screen and (max-width: 767px) {
            height: auto;
        }
    }
}
